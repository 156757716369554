<template>
	<div class="rightSide">
		<div class="rightSide_inner">
			<div class="rightSide_inner_title">
				<p style="font-size: 30px; color: #333333;">
					{{ $t("My pre-sale order") }}
				</p>
			</div>
			<div>
				<p style="color: red">{{$t('Total')}}：￥{{thePageTotal}}</p>
        <el-upload
          class="upload-demo"
          :action="$root.DownUrl + '/w1/presell/import'"
          accept=".xlsx,.csv"
          :headers="uploadHeaders"
          :auto-upload="true"
          :on-success="handleImport"
          :on-error="handleError"
          :on-progress="handleUploading"
          :show-file-list="false">
          <el-button size="small" type="">{{ $t('Import') }}</el-button>
          <div slot="tip" class="el-upload__tip">{{ $t('excelLimit') }}</div>
        </el-upload>
		<!-- 模板下载 -->
			<el-button
				type="primary"
				icon="el-icon-download"
				@click="downloadImportDemo"
			>
				{{$t("Download template")}}
			</el-button>
			<!--  -->
			</div>
			<div class="table_style">
				<el-table ref="multipleTable" header-align="center" border class="main-table" :data="tableData"
					height="530" tooltip-effect="dark" style="width: 100%; font-size: 18px; color: #515a6e"
					:header-cell-style="{ color: 'black' }">
					<el-table-column prop="order_sn" :label="$t('Order Number')" align="center" width="220" />
					<el-table-column prop="status" :label="$t('Status')" align="center">
						<template slot-scope="{row}">
							{{ $t(statusArrs[row.order_status])}}
						</template>
					</el-table-column>
					<el-table-column prop="goods_number" :label="$t('Qty')" align="center" />

					<el-table-column prop="total" :label="$t('Amount Paid')" align="center">
						<template slot-scope="{row}">
							￥{{row.total}}
						</template>
					</el-table-column>

					<el-table-column prop="created_at" :label="$t('Time')" align="center" width="220">
						<template slot-scope="{row}">
							{{row.created_at | formatDateStr}}
						</template>
					</el-table-column>
					<el-table-column prop="created_at" :label="$t('Time')" align="center" width="220">
						<template slot-scope="{row}">
							<el-button @click="toPreOrder(row)">{{$t('Order Details')}}</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>

			<div class="footer">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="pageData.page" :page-size="pageData.pageSize"
						layout="total,  prev, pager, next, jumper" :total="pageData.total" background>
					</el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
	import url1 from "@/assets/images/goods_16.jpg";
	import url2 from "@/assets/images/goods_17.jpg";
	import url3 from "@/assets/images/goods_1.jpg";
  import {
    getPresellOrder, importPresellGoods,
  } from "@/api/goods/presell.js";
	import common from "@/common/mixins/common.js";
  import { getDownCartCode } from "@/api/carts/carts";
	export default {
		mixins: [common],
		components: {},
		data() {
			return {
				statusArrs: {
					0: "Pending review",
					1: "Agree",
					2: "Refuse",
					3: "Transferred",
					4: "Close",
				},
				tableData: [],
				//currentPage4: 1,
				thePageTotal: 0,
				pageData: {
					page: 1,
					pageSize: 10,
					total: 100
				},
				lang: "",
        uploadHeaders: {
          Authorization: JSON.parse(window.sessionStorage.getItem("webToken")),
        },
        infoMessage:null
			};
		},
		created() {
			//获取到预售订单
			this.getPresellOrder()
			this.getLang();

		},
		methods: {
			/* 下载例子 */
			downloadImportDemo() {
				window.location.href = this.$root.DownUrl + '/c1/down/presell/ImportPresellGoods'
			},
			// 获取当前的语言环境
			getLang() {
				// if (this.$i18n.locale == "zh-CN") {
				// 	this.lang = this.$i18n.locale
				// } else {
				// }
				this.lang = this.$i18n.locale;
			},
			toPreOrder(row) {
				console.log(row)
				this.$router.push({
					path: 'bookingOrderInfo',
					query: {
						orderSn: row.order_sn
					}
				})
			},
			//获取到预售订单
			getPresellOrder() {
				let queryData = {
					"page": this.pageData.page,
					"page_size": this.pageData.pageSize
				}
				getPresellOrder(queryData).then((res) => {
					let resData = res.data.data
					this.tableData = resData.data;

					let resTotal = 0;
					//计算总价
					for(let key in this.tableData){
						resTotal += this.tableData[key].total;
					}
					this.thePageTotal = resTotal;

					this.pageData.page = resData.page;
					this.pageData.pageSize = resData.page_size;
					this.pageData.total = resData.total;
				})
			},

			handleSizeChange(val) {
				this.pageData.pageSize = val;
      			this.getPresellOrder();
			},
			handleCurrentChange(val) {
				this.pageData.page = val;
      			this.getPresellOrder();
			},
      handleImport(res){
        if (res.error === 0) {
			this.infoMessage.close()
			this.$message.success("import success.")
			this.getPresellOrder()
        }else{
			this.$message.success("import faled.")
        }
      },
      handleError(err){
		var msg = err.message?err.message.split('"message":')[1]:''
		var tipText = "部分商品导入成功，以下商品不符合规格导入失败"
		if(msg!==""){
			if (this.lang ==="en") {
				tipText = "Some products were successfully imported, but the following products did not meet the specifications. Import failed"
			}
		}
		this.$alert(tipText+"："+msg.replace('}',""), {
          	confirmButtonText: 'close',
        }).then(()=>{
			this.infoMessage.close()
		});
      },
      handleUploading(){
        this.infoMessage = this.$message({
          showClose: true,
          message: 'importing...',
          type: 'info',
          duration:0
        });
      }
		},
	};
</script>
<style lang="scss" scoped>
	.rightSide {
		width: 1120px;
		margin: 15px 0 0 15px;
		background-color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;

		&_inner {
			width: 1100px;

			&_title {
				width: 1100px;
				height: 68px;
			}
		}
	}

	.table_style {
		width: 1100px;
		// height: 68px;
		margin-top: 30px;
	}

	.footer {
		margin-top: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
<style lang="scss">
	.footer .el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #218da0; //修改后的背景图颜色
		color: #fff;
	}
</style>
